import { Container, Row, Col } from "react-bootstrap";
import Countdown from "react-countdown";
import { saleEndDate } from "../config";

const renderer = ({
  formatted: { days, hours, minutes, seconds },
  completed,
}) => {
  if (completed) {
    // Render a completed state
    return <></>;
  } else {
    // Render a countdown
    return (
      <div className="text-center countdown-sale d-flex justify-content-center align-items-end">
        <div className="countdown-component">
          <div className="countdown-title">days</div>
          <div className="countdown-content">{days}</div>
        </div>
        <div className="countdown-content align-self-end">:</div>
        <div className="countdown-component">
          <div className="countdown-title">hours</div>
          <div className="countdown-content">{hours}</div>
        </div>
        <div className="countdown-content align-self-end">:</div>
        <div className="countdown-component">
          <div className="countdown-title">minutes</div>
          <div className="countdown-content">{minutes}</div>
        </div>
        <div className="countdown-content align-self-end">:</div>
        <div className="countdown-component">
          <div className="countdown-title">seconds</div>
          <div className="countdown-content">{seconds}</div>
        </div>
      </div>
    );
  }
};

function SaleCountdown() {
  return (
    <Container>
      <Row>
        <Col>
          <Countdown
            zeroPadTime={2}
            date={Date.parse(saleEndDate)}
            renderer={renderer}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default SaleCountdown;
