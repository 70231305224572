import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { shopURL } from "../../config";
import { useState } from "react";

function ProductCard({ product }) {
  // eslint-disable-next-line no-unused-vars
  const [selectedVariant, setSelectedVariant] = useState(null);

  return (
    <Card className="my-3 product-card">
      <div className="product-image-wrapper">
        <Card.Img variant="top" className="product-image" src={product.image} />
        <div className="product-image-badge">-{product.discount}%</div>
      </div>
      <Card.Body className="product-card-body">
        <div className="d-flex product-card-content">
          <Card.Title className="product-title">{product.name}</Card.Title>

          {/* <div className="d-flex my-1 flex-wrap">
            {product.variations
              .filter((variation) => variation.inStock !== 0)
              .map((variation, index) => {
                return (
                  <div
                    className={
                      "product-variant px-1 my-1 " +
                      (selectedVariant != null
                        ? selectedVariant.id === variation.id
                          ? "selected-variation"
                          : ""
                        : "")
                    }
                    key={variation.id}
                    onClick={() => {
                      setSelectedVariant(variation);
                    }}
                  >
                    {variation.attribute}
                  </div>
                );
              })}
            {product.variations.length === 0 && (
              <div style={{ height: "24px" }}></div>
            )}
          </div> */}

          <div className="d-flex justify-content-center align-items-center flex-wrap ">
            <span className="original-price">{product.regularPrice} lei</span>
            <span className="new-price mx-1">{product.salePrice} lei</span>
            {product.type === "mixmatch" ? (
              <Button
                variant="add-cart mt-2 mt-lg-0"
                onClick={() => {
                  window.open(shopURL + "mixmatch/", "_blank");
                }}
              >
                Select options
              </Button>
            ) : (
              <Button
                variant="add-cart mt-2 mt-lg-0"
                onClick={() => {
                  window.open(
                    shopURL + "?add-to-cart=" + product.id + "&quantity=1",
                    "_blank"
                  );
                }}
              >
                Add to cart
              </Button>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default ProductCard;
