import hero from "../assets/img/header.jpg";
import { Container, Row, Col, Image } from "react-bootstrap";

function Header() {
  return (
    <Container fluid>
      <Row>
        <Col className="d-flex justify-content-center p-0">
          <Image src={hero} className="header-img" />
        </Col>
      </Row>
    </Container>
  );
}

export default Header;
