import "./App.css";
import Header from "./components/Header";
import BackToSite from "./components/BackToSite";
import SaleCountdown from "./components/SaleCountdown";
import ProductList from "./components/products/ProductList";
import { useEffect, useState } from "react";
import { productsURL } from "./config";
import { Dropdown, Container, Row, Col } from "react-bootstrap";

function App() {
  const [products, setProducts] = useState([]);

  const [sizeFilter, setSizeFilter] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [sort, setSort] = useState("Discount - High to Low");

  useEffect(() => {
    fetch(productsURL).then((response) => {
      response.json().then((json) => {
        console.log(json);
        // eslint-disable-next-line eqeqeq
        const newProducts = json.filter((product) => product.inStock == 1);

        setProducts(newProducts);
      });
    });
  }, []);

  const sizes = ["XS", "S", "M", "XL", "2XL", "3XL"];
  const sorts = [
    "Discount - High to Low",
    "Price - High to Low",
    "Price - Low to High",
  ];

  const categories = [
    "all",
    "dulciuri",
    "bauturi",
    "snacks",
    "mix&match",
    "mystery box",
  ];

  return (
    <div>
      <Header />
      <Container fluid className="background-black">
        <h2 className="text-center header-text">Sales up to 60%</h2>
      </Container>
      <BackToSite />
      <SaleCountdown />
      <Container className="mt-4">
        <Row>
          <Col className="d-flex justify-content-end flex-wrap">
            <Dropdown className="my-1">
              <Dropdown.Toggle variant="filter">
                {sort ? sort : ""}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {sorts.map((sort, index) => {
                  return (
                    <Dropdown.Item
                      onClick={() => {
                        setSort(sort);
                      }}
                      key={"sort-" + index}
                    >
                      {sort}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="ml-4 my-1">
              <Dropdown.Toggle variant="filter">
                {sizeFilter ? sizeFilter : "Filter by size"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setSizeFilter(null);
                  }}
                >
                  None
                </Dropdown.Item>

                {sizes.map((size, index) => {
                  return (
                    <Dropdown.Item
                      onClick={() => {
                        setSizeFilter(size);
                      }}
                      key={"size-" + index}
                    >
                      {size}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className="d-flex justify-content-center flex-wrap">
            {categories.map((category) => {
              return (
                <div
                  className={
                    "category-button mx-2 mt-4 px-2 " +
                    (selectedCategory === category ? "active-category" : "")
                  }
                  onClick={() => {
                    setSelectedCategory(category);
                  }}
                  key={"category-" + category}
                  dangerouslySetInnerHTML={{
                    __html: category.replace(" ", "&nbsp;"),
                  }}
                ></div>
              );
            })}
          </Col>
        </Row>
      </Container>
      <ProductList
        products={products}
        sizeFilter={sizeFilter}
        sort={sort}
        selectedCategory={selectedCategory}
      />
    </div>
  );
}

export default App;
