import { Container, Row, Col, Button } from "react-bootstrap";
import { ReactComponent as BackArrow } from "../assets/img/back-arrow.svg";
import { shopURL } from "../config";

function BackToSite() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Button
            variant="back"
            onClick={() => {
              window.location.href = shopURL;
            }}
          >
            <div className="d-flex ">
              <BackArrow className="mx-2 mt-1" />
              Back to site
            </div>
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default BackToSite;
