import { Container, Row, Col } from "react-bootstrap";
import ProductCard from "./ProductCard";

function ProductList({ products, sizeFilter, sort, selectedCategory }) {
  return (
    <Container className="my-5">
      <Row>
        {products
          .filter((product) => {
            let filter = 1;
            if (product.type === "variable" && sizeFilter != null) {
              filter = 0;
              product.variations.forEach((variation) => {
                if (variation.attribute === sizeFilter) {
                  filter = 1;
                }
              });
            }

            let isSelectedCategory = 0;

            if (selectedCategory === "all") {
              isSelectedCategory = 1;
            } else if (selectedCategory === "mix&match") {
              product.categories.forEach((category) => {
                if (category.includes("Uncategorized")) {
                  isSelectedCategory = 1;
                }
              });
            } else {
              let upperSelectedCategory = selectedCategory.toUpperCase();
              product.categories.forEach((category) => {
                if (category.toUpperCase().includes(upperSelectedCategory)) {
                  isSelectedCategory = 1;
                }
              });
            }

            return product.inStock !== 0 && filter && isSelectedCategory;
          })
          .sort((a, b) => {
            let c;
            let d;
            if (sort === "Discount - High to Low") {
              d = parseInt(a.discount);
              c = parseInt(b.discount);
            }
            if (sort === "Price - High to Low") {
              d = parseFloat(a.salePrice);
              c = parseFloat(b.salePrice);
            }
            if (sort === "Price - Low to High") {
              c = parseFloat(a.salePrice);
              d = parseFloat(b.salePrice);
            }

            if (c < d) {
              return -1;
            }
            if (c > d) {
              return 1;
            }
            // a must be equal to b
            return 0;
          })
          .filter((product) => {
            return product.variations.length === 0;
          })
          .map((product, index) => {
            return (
              <Col
                xs={6}
                sm={6}
                md={4}
                lg={3}
                key={product.id}
                className="py-2"
              >
                <ProductCard product={product} />
              </Col>
            );
          })}
      </Row>
    </Container>
  );
}

export default ProductList;
